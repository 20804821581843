import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { withStyles } from "@material-ui/core/styles";
import styles from "./infoboxcomponent.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const card = (
  <>
    <Card variant="outlined" sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Using EO
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Controls
        </Typography>
        <Typography variant="body2">
          [Shift ⇧] while dragging will zoom the view to the area <br />
          <br />
          [Ctrl] while dragging will query available data in area <br />
          <br />
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Usage
        </Typography>
        <Typography variant="body2">
          Usage requires an API key with sufficient credits. Prompts will popup
          during use to confirm any use of credits
          <br />
          <br />
          Attempting to use the browser without a valid API Key will open a
          prompt for requesting one.
          <br />
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://clearsky.vision/contact/"
          >
            Contact us if you have any questions
          </a>
        </Typography>
      </CardContent>
    </Card>
  </>
);

export default function InfoBoxComponent() {
  return (
    <LightTooltip
      className={styles.infobox}
      placement="left-start"
      title={card}
      enterDelay={400}
      leaveDelay={200}
      interactive
    >
      <HelpOutlineOutlinedIcon fontSize="large"></HelpOutlineOutlinedIcon>
    </LightTooltip>
  );
}
