import React, { useState, useEffect } from 'react';

function SimpleModal({ isOpen, onClose, children }) {
    const [isDragging, setIsDragging] = useState(false);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const moveModal = (e) => {
            if (isDragging) {
                setModalPosition({
                    x: modalPosition.x + (e.clientX - startPosition.x),
                    y: modalPosition.y + (e.clientY - startPosition.y),
                });
                setStartPosition({ x: e.clientX, y: e.clientY });
            }
        };

        const stopDragging = () => setIsDragging(false);

        if (isDragging) {
            document.addEventListener('mousemove', moveModal);
            document.addEventListener('mouseup', stopDragging);
        }

        return () => {
            document.removeEventListener('mousemove', moveModal);
            document.removeEventListener('mouseup', stopDragging);
        };
    }, [isDragging, startPosition, modalPosition]);

    // Effect for handling clicks outside the modal
    useEffect(() => {
        const handleClickOutside = (event) => {
            const modalContent = document.getElementById("modalContent");
            if (modalContent && !modalContent.contains(event.target)) {
                onClose(); // Close modal if click is outside modal content
            }
        };

        // Add when the modal is open and clean up on close or component unmount
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]); // Depend on isOpen to add/remove the event listener

    if (!isOpen) return null;

    const startDragging = (e) => {
        setIsDragging(true);
        setStartPosition({ x: e.clientX, y: e.clientY });
        e.stopPropagation(); // Prevent event from propagating to the modal container
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
        }}>
            <div id="modalContent" style={{
                padding: 0,
                background: '#fff',
                borderRadius: '5px',
                display: 'inline-block',
                minHeight: '220px',
                margin: '1rem',
                position: 'absolute',
                minWidth: '700px',
                boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
                justifySelf: 'center',
                left: modalPosition.x,
                top: modalPosition.y,
                cursor: 'auto',
            }} onMouseDown={e => e.stopPropagation()} // Stop propagation to prevent the modal container click handler from firing
            >
                {/* Draggable Bar */}
                <div onMouseDown={startDragging} style={{
                    cursor: 'move',
                    userSelect: 'none',
                    padding: '10px 0',
                    backgroundColor: '#f3f3f3',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                }}>
                </div>
                {children}
                <button onClick={onClose} style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '1.5rem',
                }}>×</button>
            </div>
        </div>
    );
}

export default SimpleModal;
