import React, { useState, useEffect } from "react";
//Alerts
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="standard" {...props} />;
}

export default function AlertPopup(props) {
  const [open, setOpen] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const setMessage = props.setMessage;

  const handleClose = () => {
    if (isMounted) {
      if (open) {
        setMessage(undefined);
      }
      setOpen(false);
    }
  };

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  // AlertType
  //   'error'
  //   | 'info'
  //   | 'success'
  //   | 'warning'
  //   | string

  const alertType = props.alertType ?? "error";

  return (
    <>
      {props.message ? (
        <div>
          <Snackbar
            open={open}
            autoHideDuration={props.autoHideDuration}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} color={alertType}>
              {props.message}
            </Alert>
          </Snackbar>
        </div>
      ) : null}
    </>
  );
}
