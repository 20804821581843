import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { requestTrialApiKey } from "../../services/ClearskyAPI";

export default function AuthenticationDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [trialApiKey, setTrialApiKey] = React.useState(undefined);
  const [email, setEmail] = React.useState(undefined);
  const [companyInfo, setCompanyInfo] = React.useState(undefined);

  const { authMode, setAuthMode, setErrorMessage, setInfoMessage } = props;

  const handleTrialRequest = async () => {
    const response = await requestTrialApiKey(
      email,
      companyInfo,
      setErrorMessage
    );

    if (response) {
      if (response.hasOwnProperty("emailRegistered")) {
        setEmail(undefined);
      } else if (response.hasOwnProperty("Key")) {
        setTrialApiKey(response);
      }
    }
  };

  const handleCancel = async () => {
    setOpen(false);
    setAuthMode(false);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const authModeDialog = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title">{"API Key missing"}</DialogTitle>
        <DialogContent>
          <DialogContentText component={"span"} id="alert-dialog-description">
            To interact with the browser and API, an API key is required.
            <br />
            <b>To request an API key</b>, you can fill out the information
            below.
            <br />
            <TextField
              id="email"
              error={!email || !isValidEmail(email)}
              margin="dense"
              label="Email Address"
              variant="standard"
              required
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ maxLength: 254 }}
            />
            <div>
              <TextField
                id="filled-multiline-flexible"
                error={!companyInfo}
                margin="dense"
                label="Company Information"
                multiline
                fullWidth
                variant="standard"
                required
                onChange={(e) => setCompanyInfo(e.target.value)}
                inputProps={{ maxLength: 450 }}
              />
              <br />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "red", color: "black" }}
            onClick={handleCancel}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor:
                !companyInfo || !email || !isValidEmail(email)
                  ? "grey"
                  : "green",
              color: "black",
              paddingInline: 5,
            }}
            disabled={!companyInfo || !email || !isValidEmail(email)}
            onClick={handleTrialRequest}
          >
            Request trial
          </Button>
        </DialogActions>
      </>
    );
  };

  const trialRequestedDialog = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title">
          {"Trial API Key Requested"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={"span"} id="alert-dialog-description">
            To interact with the browser and API, the requested API key can be
            used.
            <br />
            <b>Click the API key below</b>, and paste it into the API KEY field
            to start using the browser.
            <br />
            <a href="https://api.clearsky.vision" target="_blank">API docs</a>
            <br />
            <a href="https://github.com/Clearsky-Vision/clearsky_api_tools" target="_blank">GitHub example code</a>
            <br />
            <TextField
              id="apikeyfield"
              margin="dense"
              label="Trial API Key"
              variant="standard"
              value={trialApiKey.Key}
              onClick={() => {
                navigator.clipboard.writeText(trialApiKey.Key);
                setInfoMessage("API key copied!");
              }}
              fullWidth
              disabled
            />
            <div>
              <TextField
                id="enddateapikeyfield"
                margin="dense"
                label="Trial API Key - End Date"
                variant="standard"
                value={new Date(trialApiKey.ActiveEnd)}
                fullWidth
                disabled
              />
              <br />
              <TextField
                id="apikeycreditsfield"
                margin="dense"
                label="Trial API Key - Credits"
                variant="standard"
                value={trialApiKey.CreditAmount}
                fullWidth
                disabled
              />
              <br />
              <TextField
                id="apikeymaxconcurrentrequestsfield"
                margin="dense"
                label="Max Concurrent Requests"
                variant="standard"
                value={trialApiKey.MaxConcurrentConnections}
                fullWidth
                disabled
              />
              <br />
              <TextField
                id="apikeymaxrequestareafield"
                margin="dense"
                label="Max Request Area Km2"
                variant="standard"
                value={trialApiKey.MaxCompositeAreaKm2}
                fullWidth
                disabled
              />
              <br />
              <TextField
                id="apikeymaxtotalbandsfield"
                margin="dense"
                label="Max Number of Bands Per Request"
                variant="standard"
                value={trialApiKey.MaxTotalBands}
                fullWidth
                disabled
              />
              <br />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "red", color: "black" }}
            onClick={handleCancel}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </>
    );
  };

  if (!authMode) {
    return null;
  } else {
    if (!open) setOpen(true);
  }

  return (
    <div style={{ position: "absolute", zIndex: 9999999 }}>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {trialApiKey ? trialRequestedDialog() : authModeDialog()}
      </Dialog>
    </div>
  );
}
