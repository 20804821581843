import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function UserDialog(props) {
  const [open, setOpen] = React.useState(false);

  const { downloadDialogState, setDownloadDialogState } = props;

  const handleConsent = async () => {
    setOpen(false);
    await downloadDialogState.request();
  };

  const handleCancel = async () => {
    setOpen(false);
    setDownloadDialogState(undefined);
  };

  if (!downloadDialogState) {
    return null;
  } else {
    if (!open) setOpen(true);
  }

  return (
    <div style={{ position: "absolute", zIndex: 9999999 }}>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use credits on action?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={"span"} id="alert-dialog-description">
            {downloadDialogState.action} <br />
            <ul>
              <li>
                <b>{parseFloat(downloadDialogState.estimate.creditEstimate).toFixed(2)}</b> credits required for action
              </li>
              <li>
                Processing time estimate, before download:{" "}
                <b>{downloadDialogState.estimate.slowEstimate}</b> to{" "}
                <b>{downloadDialogState.estimate.fastEstimate}</b> seconds
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "red", color: "black" }}
            onClick={handleCancel}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "green", color: "black" }}
            onClick={handleConsent}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
