import React from 'react'
import olLayerTile from 'ol/layer/Tile'
import olSourceOSM from 'ol/source/OSM'
import { BasemapOption, BasemapThumbnail, Label } from "@bayer/ol-kit/core/Basemaps/styled"
import { connectToContext } from "@bayer/ol-kit/core/Provider/utils";
import { osm } from "@bayer/ol-kit/core/Basemaps/thumbnails"

class BasemapOSMMap extends React.Component {
    handleLayersChange = () => {
        this.forceUpdate()
    }

    componentDidMount() {
        this.props.map.getLayers().on('change', this.handleLayersChange)
    }

    componentWillUnmount() {
        this.props.map.getLayers().un('change', this.handleLayersChange)
    }

    onClick = () => {
        const { map, layerTypeID, onBasemapChanged } = this.props

        const source = new olSourceOSM({
            layer: 'osm',
            cacheSize: 4096,
            reprojectionErrorThreshold: 0,
            crossOrigin: 'Anonymous'
        })
        const layer = new olLayerTile({
            className: '_ol_kit_basemap_layer',
            preload: Infinity,
            extent: undefined,
            [layerTypeID]: 'osm', // make sure we can identify this layer as a layer that has been created from the ol-kit basemap component.
            source
        })
        const layers = map.getLayers()
        const layerArray = layers.getArray()
        const hasBasemap = layerTypeID && layerArray.length ? layerArray[1].get(layerTypeID) : false

        if (hasBasemap) {
            if (layerArray[1].get(layerTypeID) !== 'osm') {
                layers.removeAt(1)
            }
            layers.setAt(0, layer)
        }
        layers.changed() // ol.Collection insertAt and setAt do not trigger a change event so we fire one manually so that we can rerender to display the active and inactive BasemapOptions
        onBasemapChanged(layer)
    }

    render() {
        const { label, thumbnail, map, layerTypeID } = this.props
        const layerArray = map.getLayers().getArray()
        const isActive = layerArray.length ? layerArray[0].get(layerTypeID) === 'osm' : false

        return (
            <BasemapOption
                // className='_ol_kit_basemapOption' 
                isActive={isActive}
                onClick={this.onClick}>
                <BasemapThumbnail thumbnail={thumbnail} />
                <Label>{label}</Label>
            </BasemapOption>
        )
    }
}

BasemapOSMMap.defaultProps = {
    thumbnail: osm,
    label: "Open Street Map",
    onBasemapChanged: () => { },
    layerTypeID: '_ol_kit_basemap',
}

export default connectToContext(BasemapOSMMap)