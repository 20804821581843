import React from 'react';
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";

const InputStyled = styled(InputBase)(({ theme, readOnly }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: readOnly ? "#eee" : "#fff",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        color: readOnly ? "#333" : "#000",
        cursor: readOnly ? "not-allowed" : "text",
        "&:focus": {
            boxShadow: readOnly ? "none" : "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const InputStyledBox = ({ title, value, readOnly = true, onChange }) => {
    return (
        <FormControl sx={{ m: 1, width: 150 }} variant="filled">
            <InputLabel shrink htmlFor="configurable-input-box">{title}</InputLabel>
            <InputStyled
                id="configurable-input-box"
                value={value}
                onChange={onChange}
                readOnly={readOnly}
            />
        </FormControl>
    );
};

export default InputStyledBox;
