import { requestXYZTile } from '../../services/ClearskyAPI'


const maxConcurrentRequests = 5;
let activeRequests = 0;
const tileQueue = [];

function processTileQueue() {
    while (activeRequests < maxConcurrentRequests && tileQueue.length > 0) {
        const tile = tileQueue.shift();
        loadTile(tile);
    }
}

function loadTile(tile) {
    activeRequests++;

    const onTileLoadOrError = function () {
        activeRequests--;

        processTileQueue();
    };

    requestXYZTile(tile.src)
        .then(responseBlob => {
            if (responseBlob) {
                return responseBlob;
            }
            else {
                return ''; //return fetch('/red_tile.png').then(res => res.blob());
            }

        })
        .then(blob => {
            if (blob === '') {
                return tile.imageTile.getImage().src = ''
            }
            else {
                tile.imageTile.getImage().src = URL.createObjectURL(blob);
            }
        })
        .catch(error => {
            tile.imageTile.getImage().src = ''
        })
        .finally(() => {
            onTileLoadOrError();
        });
}

function computeTileExtent(x, y, z) {
    // This part is similar to what we had in ComputeWktFromXYZ, but you'll need to translate it to your geometry library's format
    const tilePixelSizeHeightWidth = 256;
    const initialResolution = 2 * Math.PI * 6378137 / tilePixelSizeHeightWidth;
    const originShift = 2 * Math.PI * 6378137 / 2.0;
    const resolution = initialResolution / (1 << z);

    y = (1 << z) - y - 1;

    const minX = x * tilePixelSizeHeightWidth * resolution - originShift;
    const maxX = (x + 1) * tilePixelSizeHeightWidth * resolution - originShift;
    const minY = y * tilePixelSizeHeightWidth * resolution - originShift;
    const maxY = (y + 1) * tilePixelSizeHeightWidth * resolution - originShift;

    // Assuming your geometry library uses a format like [minX, minY, maxX, maxY] for bboxes
    return [minX, minY, maxX, maxY];
}

export function toIsoStringIgnoringTimeZone(date, morning = false) {
    const dateYear = date.getFullYear();
    const dateMonth = String(date.getMonth() + 1).padStart(2, "0"); //0-indexed months
    const dateDay = String(date.getDate()).padStart(2, "0");

    return morning
        ? `${dateYear}-${dateMonth}-${dateDay}T00:00:00.000Z`
        : `${dateYear}-${dateMonth}-${dateDay}T23:59:59.999Z`;
}

export function tileLoadFunction(imageTile, src, xyzTileLimitFeature, minZoom, maxZoom, tenMeterZoomMin, tenMeterZoomMax) {
    const tile = { imageTile, src };

    const urlParts = src.split('/');
    const z = parseInt(urlParts[urlParts.length - 3], 10);
    const x = parseInt(urlParts[urlParts.length - 2], 10);
    const y = parseInt(urlParts[urlParts.length - 1], 10);

    // If the zoom level is too low or too high, do not process the tile
    if ((z < minZoom || z > maxZoom) && (!tenMeterZoomMin || !tenMeterZoomMax || !(z >= tenMeterZoomMin && z <= tenMeterZoomMax))) {
        imageTile.getImage().src = '';
        return;
    }

    const tileExtent = computeTileExtent(x, y, z);

    const intersects = xyzTileLimitFeature.getGeometry().intersectsExtent(tileExtent);

    if (!intersects) {
        imageTile.getImage().src = '';
        return;
    }

    // Add tile to the queue
    tileQueue.push(tile);

    // Process the queue
    processTileQueue();
};