let baseUrl;

if (process.env.NODE_ENV === "production") {
  baseUrl = process.env.REACT_APP_APIBASEURI;
} else {
  // baseUrl = "https://dev-api.clearsky.vision/api";
  baseUrl = "https://localhost:5011/api";
}

export { baseUrl };
