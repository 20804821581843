import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import styles from "./permanentdrawerleft.module.css";

export default function PermanentDrawerLeft(props) {
  let drawerWidth = props.drawerWidth;

  return (
    <Box sx={{ display: "flex" }} onContextMenu={(e) => e.preventDefault()}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      ></AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar sx={{ marginBottom: "30px" }}>
          <div className={styles.imagecontainer}>
            <img src="/clearsky_vision_logo.png" alt="logo" width={175} />
            <img src="/satellit0.7x.png" alt="logo" width={50} />
          </div>
        </Toolbar>
        <List>{props.children}</List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      ></Box>
    </Box>
  );
}
