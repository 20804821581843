import React from "react";
import styles from "./mapcomponent.module.css";
import debounce from "lodash.debounce";

//Openlayers
import { default as OlVectorLayer } from "ol/layer/Vector";
import { default as OlVectorSource } from "ol/source/Vector";
import { platformModifierKeyOnly } from "ol/events/condition";
import { DragBox } from "ol/interaction";
import { Feature, View } from "ol";
import WKT from "ol/format/WKT";
import { getArea } from "ol/sphere";
import olLayerTile from 'ol/layer/Tile'
import olSourceXYZ from 'ol/source/XYZ'

//Date range picker
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";

//Map Feature Click Popup
import PopupComponent from "../Popup/PopupComponent";

//ol-kit
import {
  Map,
  Popup,
  Controls,
  Draw,
  DrawPolygon,
  DrawContainer
} from "@bayer/ol-kit";

//API
import {
  getSatelliteImageMetadata,
  getMergedSatelliteImageMetadata,
  updateApiKey,
  requestActivatedSatelliteZonesGeog,
  clearXYZCache,
  getDataScienceModels
} from "../../services/ClearskyAPI";
import {
  ControlGroup,
  CurrentLocation,
  ScaleLine,
  ZoomIn,
  ZoomOut,
} from "@bayer/ol-kit/core/Controls";
import { parseISO, addDays } from "date-fns";
import { Button } from "@mui/material";
import PermanentDrawerLeft from "../Drawer/PermanentDrawerLeftComponent";
import DragDropBand from "../DragDrop/DragDropBandComponent";
import DrawerAccordion from "../Accordion/DrawerAccordionComponent";
import { Typography } from "@material-ui/core";
import IndexDropDown from "../DropDown/IndexDropDownComponent";
import OptionsDropDown from "../DropDown/OptionsDropDownComponent";
import InputStyledBox from "../InputStyledBox/InputStyledBoxComponent";
import CSVBasemapContainer from "../Basemaps/CSVBasemapContainerComponent";

function toIsoStringIgnoringTimeZone(date, morning = false) {
  const dateYear = date.getFullYear();
  const dateMonth = String(date.getMonth() + 1).padStart(2, "0"); //0-indexed months
  const dateDay = String(date.getDate()).padStart(2, "0");

  return morning
    ? `${dateYear}-${dateMonth}-${dateDay}T00:00:00.000Z`
    : `${dateYear}-${dateMonth}-${dateDay}T23:59:59.999Z`;
}

const defaultBands = [
  "B2",
  "B3",
  "B4",
  "B5",
  "B6",
  "B7",
  "B8",
  "B8A",
  "B11",
  "B12",
];

const defaultBandsWithUncertainty = [...defaultBands,
  "B2_UNCERTAINTY",
  "B3_UNCERTAINTY",
  "B4_UNCERTAINTY",
  "B5_UNCERTAINTY",
  "B6_UNCERTAINTY",
  "B7_UNCERTAINTY",
  "B8_UNCERTAINTY",
  "B8A_UNCERTAINTY",
  "B11_UNCERTAINTY",
  "B12_UNCERTAINTY",
]

// const calculateDaysDiff = (startDate, endDate) => {
//   const timeDiff = endDate - startDate;
//   return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
// }

const calculateDaysDiff = (startDate, endDate) => {
  // Convert both dates to UTC
  const startUTC = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const endUTC = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  // Calculate the difference in days based on UTC values
  const timeDiff = endUTC - startUTC;
  return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
}

const today = new Date(new Date().setHours(0, 0, 0, 0));
const liveDateStart = new Date((new Date(Date.UTC(2023, 2, 2, 0, 0, 0, 0))).setHours(0, 0, 0, 0));
//const liveDateEnd = new Date((new Date(Date.UTC(2023, 10, 15, 0, 0, 0, 0))).setHours(0, 0, 0, 0)); // <<< use if end_date is constant, e.g. 2023-11-15 
const liveDateEnd = calculateDaysDiff(liveDateStart, today) % 2 === 0
  ? today
  : addDays(today, -1);

export default class MapComponent extends React.Component {
  constructor(props) {
    super();

    this.state = {
      apiKey: sessionStorage.getItem("apiKey") || "",
      maxConcurrentConnections: null,
      maxCompositeArea: null,
      maxTotalBands: null,
      defaultBasemap: null,
      boundingBoxWkt: "",
      selectedFeatures: [],
      map: null,
      requestingSatelliteZones: false,
      popUpState: null,
      popUpSelectedArea: null,
      satelliteZonesMetadata: null,
      features: [],
      vectorLayer: null,
      selectedCoord: null,
      loading: null,
      selectedBands: [
        "B2",
        "B3",
        "B4",
        "B5",
        "B6",
        "B7",
        "B8",
        "B8A",
        "B11",
        "B12",
      ],
      selectedResolution: 10,
      setDownloading: props.setDownloading,
      setDownloadSize: props.setDownloadSize,
      setDownloadProgress: props.setDownloadProgress,
      setDownloadDialogState: props.setDownloadDialogState,
      setLoading: props.setLoading,
      setLoadingText: props.setLoadingText,
      setErrorMessage: props.setErrorMessage,
      setInfoMessage: props.setInfoMessage,
      setSuccessMessage: props.setSuccessMessage,
      setAuthMode: props.setAuthMode,
      privilegedMode: false,
      xyzTestMode: false,
      minZoom: 5,
      maxZoom: 13,
      tenMeterZoomMin: null,
      tenMeterZoomMax: null,
      dataScienceModelId: undefined,
      dataScienceModelOptions: [],
      completed: undefined,
      selectedDate: addDays(liveDateEnd, -4), //new Date((new Date(Date.UTC(2023, 7, 1, 0, 0, 0, 0))).setHours(0, 0, 0, 0))
      xyzTileLimitFeature: null,
      uncertaintyThreshold: 0,
    };

    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.clearXYZCacheForDateTestMode = this.clearXYZCacheForDateTestMode.bind(this);
    this.onBoundingBoxChangeDebounced = debounce(
      this.onBoundingBoxChangeDebounced,
      2000
    );
    this.updateApiKeyDebounced = debounce(this.updateApiKeyDebouncedMethod, 500);
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if xyzTestMode has changed from false to true
    if (!prevState.xyzTestMode && this.state.xyzTestMode) {
      this.getDataScienceModelOptions();
    }
  }

  async componentDidMount() {
    const url = new URL(window.location.href);
    const existing = Object.fromEntries(url.searchParams.entries());
    const format = new WKT();

    if (existing.apiKey || this.state.apiKey) {
      const key = existing?.apiKey || this.state.apiKey;
      const apiKeyInfo = await updateApiKey(key, this.state.setErrorMessage);
      this.setState({ apiKey: key, maxConcurrentConnections: apiKeyInfo.MaxConcurrentConnections, maxCompositeArea: apiKeyInfo.MaxCompositeAreaKm2, maxTotalBands: apiKeyInfo.MaxTotalBands, privilegedMode: apiKeyInfo.Privileged });
    }

    if (existing.startDate) {
      let selectedDate = parseISO(existing.startDate)

      if (selectedDate > liveDateEnd) {
        selectedDate = liveDateEnd
      }

      this.setState({
        selectedDate: selectedDate,
      });
    }

    if (existing.boundingbox) {
      const feature = format.readFeature(existing.boundingbox, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });

      this.setState({ boundingBoxWkt: existing.boundingbox }, () =>
        this.requestMetadata(feature)
      );
    }

    if (existing.authMode == 5) {
      this.state.setAuthMode(true)
    }

    if (existing.basemap == "rgb" || existing.basemap == "ndvi") {
      this.setState({
        defaultBasemap: existing.basemap,
      });
    }

    if (existing.expandedzoom) {
      this.setState({
        tenMeterZoomMin: 15,
        tenMeterZoomMax: 16,
      })
    }

    const xyzTileLimitPolygon = await requestActivatedSatelliteZonesGeog(this.state.setErrorMessage)

    const feature = format.readFeature(xyzTileLimitPolygon.Geog, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    this.setState({ xyzTileLimitFeature: feature });
  }

  addSatelliteMetadataFeatures(satelliteImageMetadata) {
    const format = new WKT();

    const featuresToAdd = [];

    satelliteImageMetadata.forEach((metadata) => {
      const feature = format.readFeature(metadata.Geog, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });

      if (metadata.hasOwnProperty("Zone")) {
        feature.setId(
          metadata.Zone + "-" + metadata.XPosition + "-" + metadata.YPosition
        );
        feature.set(
          "name",
          metadata.Zone + "-" + metadata.XPosition + "-" + metadata.YPosition
        );
      } else {
        feature.setId("Composite");
        feature.set("name", "Composite");
      }

      featuresToAdd.push(feature);
    });

    this.state.vectorLayer.setSource(
      new OlVectorSource({
        features: featuresToAdd,
      })
    );

    this.setState({ featuresToAdd });
  }

  loadZones = async (feature) => {
    try {
      const format = new WKT();

      const aoi = format.writeFeature(feature, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });

      const fromIsoString = toIsoStringIgnoringTimeZone(this.state.selectedDate, true);
      const toIsoString = toIsoStringIgnoringTimeZone(this.state.selectedDate, false);

      this.state.setLoadingText("Loading satellitezones");
      this.state.setLoading(true);
      let satelliteImageMetadata;

      if (this.props.tileImageryMode) {
        satelliteImageMetadata = await getSatelliteImageMetadata(
          aoi,
          fromIsoString,
          toIsoString,
          this.state.dataScienceModelId,
          this.state.setErrorMessage,
          this.state.setAuthMode
        );
      } else {
        satelliteImageMetadata = await getMergedSatelliteImageMetadata(
          aoi,
          fromIsoString,
          toIsoString,
          this.state.dataScienceModelId,
          this.state.setErrorMessage,
          this.state.setAuthMode
        );
      }

      if (!satelliteImageMetadata) {
        this.state.setLoading(false);
        return null;
      }

      let data = null;

      if (Array.isArray(satelliteImageMetadata)) {
        data = satelliteImageMetadata;
      } else {
        data = [satelliteImageMetadata];
      }

      this.setState(
        {
          satelliteZonesMetadata: data,
        },
        () => this.state.setLoading(false)
      );

      return data;
    } catch (e) {
      this.state.setLoading(false);
      this.state.setErrorMessage(e.message);
      return null;
    }
  };

  onMapClick = async (state) => {
    if (state.loading != null && !state.loading) {
      const selectedFeature = state.features[0];
      let selectedArea = undefined

      if (!selectedFeature) {
        return
      }

      if (selectedFeature.id_ === "Composite") {
        const wktFormatter = new WKT();
        const wgs84FeatureWkt = wktFormatter.writeFeature(selectedFeature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        selectedArea = { "wkt": wgs84FeatureWkt }
      } else if (selectedFeature.id_.includes("-")) {
        const satelliteZoneInfo = selectedFeature.id_.split("-")
        selectedArea = { "zone": satelliteZoneInfo[0], "xpos": satelliteZoneInfo[1], "ypos": satelliteZoneInfo[2] }

      }

      this.setState({ popUpState: state, popUpSelectedArea: selectedArea });
    }
  };

  calculatePolygonArea = function (polygon) {
    const area = Math.abs(getArea(polygon));
    const output = (Math.round((area / 1000000) * 100) / 100);
    return output;
  };

  onMapInit = async (map) => {
    const vectorLayer = new OlVectorLayer({
      title: "SatelliteZones",
      source: new OlVectorSource({
        features: this.state.features,
      }),
      zIndex: 0,
    });

    const existingQueryParameters = new URL(
      window.location.href
    ).searchParams.entries();

    const viewParameter = Array.from(existingQueryParameters).filter(
      (e) => e[0] === "view"
    );

    const viewBox = new View()
      .getProjection()
      .getExtent()
      .map((val) => val * 1.2); //Limit view to 1.2 times mercator map

    if (!viewParameter.length) {
      const europeCenteredView = new View({
        center: [1227297.3859958411, 6335655.357762816], //germany somewhere
        extent: viewBox,
        zoom: 6,
        maxZoom: 20,
      });

      map.setView(europeCenteredView);
    } else {
      const newView = new View({
        center: map.getView().getCenter(),
        extent: viewBox,
        zoom: map.getView().getZoom(),
        maxZoom: 20,
      });
      map.setView(newView);
    }

    map.addLayer(vectorLayer);

    const dragBox = new DragBox({
      condition: platformModifierKeyOnly,
    });

    map.addInteraction(dragBox);

    dragBox.on("boxend", async () => {
      const dragBoxGeom = dragBox.getGeometry();
      const dragBoxFeature = new Feature({ geometry: dragBoxGeom });

      await this.requestMetadata(dragBoxFeature);
    });

    // clear selection when drawing a new box and when clicking on the map
    dragBox.on("boxstart", () => {
      this.state.vectorLayer.setSource(
        new OlVectorSource({
          features: [],
        })
      );
      this.setState({
        selectedFeatures: [],
        satelliteZonesMetadata: null,
        popUpState: null,
        requestingSatelliteZones: true,
      });
    });

    // nice to have map set on the window while debugging
    this.setState({
      map: map,
      vectorLayer: vectorLayer
    });
  };

  requestMetadata = async (feature) => {

    if (!this.props.tileImageryMode && this.state.maxTotalBands && this.state.selectedBands.length > this.state.maxTotalBands) {
      this.state.setErrorMessage(`Max total bands exceeded, please select ${this.state.maxTotalBands} or less`);
      return;
    }

    const geom = feature.getGeometry();
    const boxArea = this.calculatePolygonArea(geom);

    if (this.props.tileImageryMode || !this.state.maxCompositeArea || boxArea < this.state.maxCompositeArea) {
      const satelliteImageMetadata = await this.loadZones(feature);

      if (satelliteImageMetadata != null) {
        this.addSatelliteMetadataFeatures(satelliteImageMetadata);
      }
    } else {
      this.state.setErrorMessage(
        `~${boxArea} km2 requested, area must be below ~${this.state.maxCompositeArea} square kilometers`
      );
    }

    this.setState({ requestingSatelliteZones: false });
  };

  addBoundingBoxToUrl = (boundingBoxWkt) => {
    const addSearchParams = (url, params = {}) => {
      const existing = url.searchParams.entries();
      return new URL(
        `${url.origin}${url.pathname}?${new URLSearchParams([
          ...Array.from(existing).filter((e) => e[0] !== "boundingbox"),
          ...Object.entries(params),
        ]).toString()}`
      );
    };

    const location = window.location.href;
    const newLocation = addSearchParams(new URL(location), {
      boundingbox: boundingBoxWkt,
    });
    window.history.replaceState(null, "", newLocation);
  };

  addStartDateToUrl = (startDate) => {
    const addSearchParams = (url, params = {}) => {
      const existing = url.searchParams.entries();
      return new URL(
        `${url.origin}${url.pathname}?${new URLSearchParams([
          ...Array.from(existing).filter((e) => e[0] !== "startDate"),
          ...Object.entries(params),
        ]).toString()}`
      );
    };

    const location = window.location.href;
    const newLocation = addSearchParams(new URL(location), {
      startDate: startDate.toISOString(),
    });
    window.history.replaceState(null, "", newLocation);
  };

  handleDateSelect(date) {
    if (date) {
      this.addStartDateToUrl(date);
    }

    this.setState({ selectedDate: date }, () => this.refreshXYZLayer(date));
  }

  refreshXYZLayer = (date) => {
    const map = this.state.map;
    if (!map) return;

    map.getLayers().forEach(layer => {
      if (layer instanceof olLayerTile && layer.getSource() instanceof olSourceXYZ) {
        const layerType = layer.get('_ol_kit_basemap')

        if (layerType && layerType !== 'osm') {
          const source = layer.getSource();
          let currentURL = source.getUrls()[0];

          const dateRegex = /\/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)\//;
          const match = currentURL.match(dateRegex);

          if (match && match[1]) {
            currentURL = currentURL.replace(match[1], toIsoStringIgnoringTimeZone(date, true));

            source.setUrl(currentURL);

            source.clear(true);
            source.refresh();
          }
        }

      }
    });
  }

  generateUtcMinMaxDate(min) {
    if (min) {
      return liveDateStart;// new Date(Date.UTC(2023, 4, 1, 0, 0, 0, 0));
    }

    return liveDateEnd;
  }

  handleBoundingBoxWktChange = async (boundingBoxWkt) => {
    this.setState({
      boundingBoxWkt,
    });

    await this.onBoundingBoxChangeDebounced(boundingBoxWkt);
  };

  onBoundingBoxChangeDebounced = async (boundingBoxWkt) => {
    const format = new WKT();
    const feature = format.readFeature(boundingBoxWkt, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    await this.requestMetadata(feature);
  };

  handleDrawEnd = (e) => {
    const wktFormatter = new WKT();
    const wgs84FeatureWkt = wktFormatter.writeFeature(e, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    this.setState({ boundingBoxWkt: wgs84FeatureWkt }, () => this.requestMetadata(e));
  }

  clearXYZCacheForDateTestMode = async () => {
    const selectedDateIsoString = toIsoStringIgnoringTimeZone(this.state.selectedDate, true)

    await clearXYZCache(
      selectedDateIsoString,
      this.state.xyzTestMode,
      this.state.setErrorMessage
    );
  };

  async updateApiKeyDebouncedMethod(value) {

    const apiKeyInfo = await updateApiKey(value, this.state.setErrorMessage);
    this.setState({
      maxConcurrentConnections: apiKeyInfo.MaxConcurrentConnections,
      maxCompositeArea: apiKeyInfo.MaxCompositeAreaKm2,
      maxTotalBands: apiKeyInfo.MaxTotalBands,
      privilegedMode: apiKeyInfo.Privileged
    });
  }

  getDataScienceModelOptions = async () => {
    const response = await getDataScienceModels(this.state.setErrorMessage);
    const models = response.DataScienceModelDtos;

    this.setState({
      dataScienceModelOptions: models.sort((a, b) => a.Id - b.Id)
    });
  }

  renderOptions = () => {

    if (this.state.dataScienceModelOptions && this.state.dataScienceModelOptions.length > 0 && !this.state.dataScienceModelId) {
      const firstOption = this.state.dataScienceModelOptions[0]
      this.setState({ dataScienceModelId: firstOption.Id })
    }

    return this.state.dataScienceModelOptions.map((model) => {
      const modelJson = JSON.stringify(model);
      return <option key={model.Id} value={model.Id}>{modelJson}</option>;
    });
  }

  render() {
    return (
      <>
        <div>
          <span className={styles.inputlabelboundingbox}>
            <h2>BoundingBox:</h2>
          </span>
          <input
            className={styles.inputfieldboundingbox}
            value={this.state.boundingBoxWkt}
            placeholder="input your boundingbox WKT here"
            onInput={(e) => {
              this.addBoundingBoxToUrl(e.target.value);
              this.handleBoundingBoxWktChange(e.target.value);
            }}
          />
        </div>
        <div>
          <span className={styles.inputlabel}>
            <h2>API KEY:</h2>
          </span>
          <input
            className={styles.inputfield}
            value={this.state.apiKey}
            placeholder="input your API key here"
            onInput={(e) => {
              const value = e.target.value;
              this.setState({ apiKey: value });
              this.updateApiKeyDebounced(value);
            }}
          />

          {this.state.apiKey ? null : (
            <Button
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                color: "black",
                cursor: "pointer",
                marginTop: "98px",
                marginLeft: "785px",
                position: "absolute",
                zIndex: 999999,
              }}
              onClick={() => this.state.setAuthMode(true)}
            >
              Get API Key
            </Button>
          )}
        </div>

        <PermanentDrawerLeft drawerWidth={400}>
          <DrawerAccordion title={"Date"} standalone>
            <Calendar
              d
              date={this.state.selectedDate}
              minDate={this.generateUtcMinMaxDate(true)}
              maxDate={this.generateUtcMinMaxDate(false)}
              onChange={this.handleDateSelect}
              disabledDay={(date) => {
                const daysDiff = calculateDaysDiff(date, liveDateStart);
                return daysDiff % 2 !== 0;
              }}

            />
          </DrawerAccordion>
          <DrawerAccordion title={"Options"} standalone>
            <OptionsDropDown title={"resolution"} selectedOption={this.state.selectedResolution} setSelectedOption={(option) => this.setState({ selectedResolution: option })}>
              <option value={10}>10m / pixel</option>
              <option value={20}>20m / pixel</option>
              <option value={40}>40m / pixel</option>
              <option value={80}>80m / pixel</option>
              <option value={160}>160m / pixel</option>
            </OptionsDropDown>
            {this.state.privilegedMode ? <OptionsDropDown title={"xyz test mode"} selectedOption={this.state.xyzTestMode} setSelectedOption={(option) => this.matchselectedOptionToValue(option)} >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </OptionsDropDown> : null}
            {this.state.privilegedMode && this.state.xyzTestMode ?
              <OptionsDropDown title={"datasciencemodel"} selectedOption={this.state.dataScienceModel} setSelectedOption={(option) => this.setState({ dataScienceModelId: parseInt(option) })} >
                {this.renderOptions()}
              </OptionsDropDown> : null}
            {this.state.maxConcurrentConnections ? <InputStyledBox title="Max Concur. Requests" value={this.state.maxConcurrentConnections} /> : null}
            {this.state.maxCompositeArea ? <InputStyledBox title="Max Km2 / Req" value={this.state.maxCompositeArea} /> : null}
            {this.state.maxTotalBands ? <InputStyledBox title="Max Total Bands" value={this.state.maxTotalBands} /> : null}
            {this.state.privilegedMode ?
              <Button
                key={this.state.xyzTestMode}
                variant="contained"
                color="warning"
                style={{ paddingTop: "2%", marginLeft: "4%", marginTop: "25px", maxWidth: "40%", backgroundColor: "#B2B2B2" }}
                onClick={this.clearXYZCacheForDateTestMode}
              >
                {this.state.xyzTestMode ? "Clear TestMode cache" : "Clear Regular cache"}
              </Button>
              : null}
            {this.state.privilegedMode ? <InputStyledBox title="Uncertainty Threshold" value={this.state.uncertaintyThreshold} readOnly={false} onChange={(event) => {
              let newValue = parseInt(event.target.value, 10) || 0;

              if (newValue < 0 || newValue > 255) {
                newValue = 0;
              }

              this.setState({ uncertaintyThreshold: newValue });
            }} /> : null}

          </DrawerAccordion>
          <DrawerAccordion
            title={"Index"}
          >
            <Typography>
              Add indexes to be calculated using the formula
              {<br />}
              (B1 - B2) / (B1 + B2)
              {<br />}
            </Typography>
            <IndexDropDown
              defaultBands={defaultBands}
              addIndexToBands={(newIndex) => {
                this.setState((prevState) => {
                  if (prevState.selectedBands.includes(newIndex)) {
                    return prevState;
                  }

                  return {
                    selectedBands: [...prevState.selectedBands, newIndex],
                  };
                });
              }}
              setInfoMessage={this.state.setInfoMessage}
            />
          </DrawerAccordion>
          <DrawerAccordion
            title={"Band Order"}
          >
            <Typography>
              Reorder bands by dragging, right click to remove a band
              <br />
              <br />
            </Typography>
            <DragDropBand
              defaultBands={this.state.privilegedMode ? defaultBandsWithUncertainty : defaultBands}
              selectedBands={this.state.selectedBands}
              setSelectedBands={(bands) =>
                this.setState({ selectedBands: bands })
              }
            />
          </DrawerAccordion>
        </PermanentDrawerLeft>

        <div id="olmap" className="olmap-container"></div>
        <Map logoPosition="none" onMapInit={this.onMapInit} fullScreen>
          <CSVBasemapContainer minZoom={this.state.minZoom} maxZoom={this.state.maxZoom} tenMeterZoomMin={this.state.tenMeterZoomMin} tenMeterZoomMax={this.state.tenMeterZoomMax}
            defaultBasemap={this.state.defaultBasemap} uncertaintyThreshold={this.state.uncertaintyThreshold} dataScienceModelId={this.state.dataScienceModelId}
            xyzTileLimitFeature={this.state.xyzTileLimitFeature} date={this.state.selectedDate} style={{ left: "410px", width: "90px", height: "90px" }} />
          <Controls>
            <ScaleLine
              map={this.state.map}
              orientation={"horizontal"}
              units={"metric"}
            />
            <ControlGroup map={this.state.map} orientation={"horizontal"}>
              <CurrentLocation map={this.state.map} />
              <ZoomIn map={this.state.map} />
              <ZoomOut map={this.state.map} />
            </ControlGroup>
          </Controls>
          <DrawContainer
            map={this.state.map}
            showMeasurements={false}
            style={{ position: 'absolute', top: '7.8rem', left: '425px', zIndex: 1000, backgroundColor: 'rgba(255, 255, 255, 0.6)' }} >
            <Draw onDrawFinish={this.handleDrawEnd}>
              <DrawPolygon tooltipTitle={"Draw Polygon"} />
            </Draw>
          </DrawContainer>
          {!this.state.requestingSatelliteZones ? (
            <>
              <Popup onMapClick={this.onMapClick}>
                {this.state.popUpState ? (
                  <PopupComponent
                    selectedBands={this.state.selectedBands}
                    selectedResolution={this.state.selectedResolution}
                    map={this.state.map}
                    popUpState={this.state.popUpState}
                    popUpSelectedArea={this.state.popUpSelectedArea}
                    metaData={this.state.satelliteZonesMetadata}
                    dataScienceModelId={this.state.dataScienceModelId}
                    setLoading={this.state.setLoading}
                    setLoadingText={this.state.setLoadingText}
                    setDownloading={this.state.setDownloading}
                    setDownloadProgress={this.state.setDownloadProgress}
                    setDownloadSize={this.state.setDownloadSize}
                    setDownloadDialogState={this.state.setDownloadDialogState}
                    setErrorMessage={this.state.setErrorMessage} />
                ) : null}
              </Popup>
            </>
          ) : null}
        </Map >
      </>
    );
  }

  matchselectedOptionToValue(option) {
    let booleanValue = undefined;
    if (option === 'true') {
      booleanValue = true;
    } else if (option === 'false') {
      booleanValue = false;
    }

    if (booleanValue !== undefined) {
      this.setState({ xyzTestMode: booleanValue });
    }
  }
}
