import React, { Component, createRef } from 'react'
import { BasemapThumbnail, Label, BasemapSliderContainer, BasemapOption } from "@bayer/ol-kit/core/Basemaps/styled"
import { rgb, ndvi } from '../../thumbnails'
import { uncertainty, uncertainty_rgb } from '../../uncertainty_thumbnail'
import { osm } from "@bayer/ol-kit/core/Basemaps/thumbnails"
import BasemapRGBMap from './BasemapRGBMapComponent'
import BasemapUncertaintyMap from './BasemapUncertaintyMapComponent'
import BasemapRGBThresholdMap from './BasemapRGBThresholdMapComponent'
import BasemapNDVIMap from './BasemapNDVIMapComponent'
import BasemapOSMMap from './BasemapOSMMapComponent'
import { connectToContext } from "@bayer/ol-kit/core/Provider/utils"
import { baseUrl } from '../../config'
import olLayerTile from 'ol/layer/Tile'
import olSourceXYZ from 'ol/source/XYZ'
import { tileLoadFunction, toIsoStringIgnoringTimeZone } from './maputils';

class CSVBasemapContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showBasemaps: false,
            basemapOptions: props.basemapOptions
        }

        this.basemapRefs = props.basemapOptions.map(() => createRef());
    }

    componentDidMount() {
        if (this.props.xyzTileLimitFeature) {
            this.setBasemap(this.props.defaultBasemap);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showBasemaps !== this.state.showBasemaps && this.state.showBasemaps === true) {
            this.props.map.on('click', this.hideBasemaps)
        } else {
            this.props.map.un('click', this.hideBasemaps)
        }

        if (!prevProps.xyzTileLimitFeature && this.props.xyzTileLimitFeature && this.props.defaultBasemap) {
            this.setBasemap(this.props.defaultBasemap);
        }
    }

    setBasemap = (basemapKey) => {
        const { map, date, xyzTileLimitFeature, dataScienceModelId, minZoom, maxZoom, tenMeterZoomMin, tenMeterZoomMax } = this.props;
        const dateIsoString = toIsoStringIgnoringTimeZone(date)
        if (!date) return;

        if (!xyzTileLimitFeature) return;

        const layerTypeID = '_ol_kit_basemap'

        // Determine URL and other config based on basemapKey
        const bandName = basemapKey;  // assuming basemapKey is something like 'rgb'
        const url = dataScienceModelId ? `${baseUrl}/DataScience/xyz/${dataScienceModelId}/${bandName}/${dateIsoString}/{z}/{x}/{y}` : `${baseUrl}/SatelliteImages/preview/${bandName}/${dateIsoString}/{z}/{x}/{y}`;
        const source = new olSourceXYZ({
            url: url,
            crossOrigin: 'Anonymous',
            tileLoadFunction: (imageTile, src) => tileLoadFunction(imageTile, src, xyzTileLimitFeature, minZoom, maxZoom, tenMeterZoomMin, tenMeterZoomMax),
        });
        const layer = new olLayerTile({
            className: '_ol_kit_basemap_layer',
            preload: 0,
            [layerTypeID]: bandName,
            source
        });

        const layers = map.getLayers();
        const hasBasemap = layerTypeID && layers.getArray().length ? layers.getArray()[1].get(layerTypeID) : false;

        if (hasBasemap) {
            layers.setAt(1, layer);
        } else {
            layers.insertAt(1, layer);
        }
        layers.changed();  // Manually trigger change event
        this.onBasemapChanged(layer);
    };

    hideBasemaps = () => {
        this.setState({ showBasemaps: false })
    }

    onBasemapChanged = (layer) => {
        const clonedBasemapOptions = [...this.state.basemapOptions]
        const selectedBaseMap = layer.get('_ol_kit_basemap')
        const newBasemap = clonedBasemapOptions.find(basemap => basemap.key === selectedBaseMap)
        const newIndexOfBasemap = clonedBasemapOptions.indexOf(newBasemap)
        const selectedBasemap = clonedBasemapOptions.splice(newIndexOfBasemap, 1)


        this.setState({ showBasemaps: false, basemapOptions: [...selectedBasemap, ...clonedBasemapOptions] })
    }

    render() {
        const { showBasemaps, basemapOptions } = this.state
        const { variation, style, date, xyzTileLimitFeature, dataScienceModelId, uncertaintyThreshold, minZoom, maxZoom, tenMeterZoomMin, tenMeterZoomMax } = this.props

        return basemapOptions.map((basemap, i) => {
            const zIndex = basemapOptions.length - i

            if (showBasemaps) {
                return (
                    <div className="basemap-container" key={i}>
                        <BasemapSliderContainer
                            variation={variation}
                            style={style}
                            zIndex={zIndex}
                            left={0}
                            bottom={14 + (i * 95)}
                            key={i}>
                            {React.cloneElement(basemap, {
                                onBasemapChanged: (layer) => this.onBasemapChanged(layer),
                                date: toIsoStringIgnoringTimeZone(date, true),
                                xyzTileLimitFeature: xyzTileLimitFeature,
                                dataScienceModelId: dataScienceModelId,
                                uncertaintyThreshold: uncertaintyThreshold,
                                minZoom: minZoom,
                                maxZoom: maxZoom,
                                tenMeterZoomMin: tenMeterZoomMin,
                                tenMeterZoomMax: tenMeterZoomMax,
                            })}
                        </BasemapSliderContainer>
                    </div>
                )
            } else {
                return (
                    <div className="basemap-container" key={i}>
                        <BasemapSliderContainer
                            variation={variation}
                            style={style}
                            zIndex={zIndex}
                            onClick={() => this.setState({ showBasemaps: true })}
                            key={i}
                            noBoxShadow={i !== 0}>
                            <BasemapOption>
                                <BasemapThumbnail thumbnail={basemap.props.thumbnail} />
                                <Label>{basemap.props.label}</Label>
                            </BasemapOption>
                        </BasemapSliderContainer>
                    </div>
                )
            }
        })
    }
}

CSVBasemapContainer.defaultProps = {
    basemapOptions: [
        <BasemapOSMMap key='osm' thumbnail={osm} label={"Open Street Map"} />,
        <BasemapRGBMap key='rgb' thumbnail={rgb} label={"ClearSKY Vision RGB"} />,
        <BasemapUncertaintyMap key='uncertainty' thumbnail={uncertainty} label={"ClearSKY Vision Uncertainty"} />,
        <BasemapNDVIMap key='ndvi' thumbnail={ndvi} label={"ClearSKY Vision NDVI"} />,
        <BasemapRGBThresholdMap key='THR_RGB' thumbnail={uncertainty_rgb} label={"ClearSKY Vision RGB Threshold"} />,
    ],
    date: undefined,
    xyzTileLimitFeature: undefined,
    dataScienceModelId: undefined,
    uncertaintyThreshold: undefined,
    minZoom: undefined,
    maxZoom: undefined,
    tenMeterZoomMin: undefined,
    tenMeterZoomMax: undefined,
}

export default connectToContext(CSVBasemapContainer)