import React, { useState } from "react";
import styles from "./popup.module.css";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  downloadImageFromId,
  downloadImageFromBoundingBoxDate,
  getImageProcessingEstimate,
} from "../../services/ClearskyAPI";
import { connectToContext } from "@bayer/ol-kit/core/Provider";
import SatelliteProductsComponent from "../SatelliteProducts/SatelliteProductsComponent";
import SimpleModalComponent from "../SimpleModal/SimpleModalComponent"

function PopupComponent(props) {
  const {
    selectedBands,
    selectedResolution,
    popUpState: { features },
    popUpSelectedArea,
    metaData: _metaData,
    setDownloading,
    setDownloadProgress,
    setDownloadSize,
    setDownloadDialogState,
    setLoading,
    setLoadingText,
    setErrorMessage,
    dataScienceModelId
  } = props;

  const [isSatelliteProductsModalOpen, setSatelliteProductsModalOpen] = useState(false);
  const [selectedImageDate, setSelectedImageDate] = useState(null);

  const olFeature = features[0];

  if (olFeature == null) {
    return <></>;
  }

  const metadata = _metaData.reduce((prev, curr) => {
    let key = "Composite";

    if (curr.hasOwnProperty("Zone")) {
      key = `${curr.Zone}-${curr.XPosition}-${curr.YPosition}`;
    }

    prev[key] = curr;
    return prev;
  }, {});

  const feature = metadata[olFeature.id_];
  const tileImageryMode = feature.hasOwnProperty("Zone");
  const imageryDateList = tileImageryMode ? feature.SatelliteImageZones : feature.SatelliteMergedImageDates;

  let title = `Composite Imagery`;

  if (tileImageryMode) {
    title = `UTM${feature.Zone} ${feature.XPosition}-${feature.YPosition}`;
  }

  return (
    <>
      <div>
        <br />
        <div className={styles.centertext}>
          <h3>{title}</h3>
        </div>

        <ul className={styles.scroll}>
          {createDateList(
            tileImageryMode,
            imageryDateList,
            selectedBands,
            selectedResolution,
            dataScienceModelId,
            setErrorMessage
          )}
        </ul>
      </div>
      <SimpleModalComponent
        isOpen={isSatelliteProductsModalOpen}
        onClose={() => setSatelliteProductsModalOpen(false)}
      >
        <SatelliteProductsComponent popUpSelectedAreaDate={{ ...popUpSelectedArea, selectedImageDate }} setErrorMessage={setErrorMessage} />
      </SimpleModalComponent>
    </>
  );

  function createDateList(
    tileImageryMode,
    imageryDateList,
    selectedBands,
    resolution,
    dataScienceModelId,
    setErrorMessage
  ) {
    return imageryDateList.map((date, i) => {
      const imageDate = date.ImageDate;
      const imageID = date.SatelliteImageId;
      const formattedImageDate = imageDate.slice(0, 10);
      const downloadTitle = `Download ${formattedImageDate} as .tif` + dataScienceModelId ? ` for model ${dataScienceModelId}` : '';
      const satelliteProductsRangeTitle = `View original imagery used for date, and expected for future images`;

      return (
        <li className={styles.listItem} key={i}>
          <span>{formattedImageDate}</span>
          {createDownloadSpan(tileImageryMode, downloadTitle, imageDate, formattedImageDate, imageID, selectedBands, resolution, dataScienceModelId, setErrorMessage)}
          {dataScienceModelId ? null : createSatelliteProductsRangeSpan(satelliteProductsRangeTitle, imageDate)}
        </li>
      );
    });
  }

  function createDownloadSpan(
    tileImageryMode,
    downloadTitle,
    imageDate,
    formattedImageDate,
    imageID,
    selectedBands,
    resolution,
    dataScienceModelId,
    setErrorMessage
  ) {
    return (
      <span
        className={styles.icon}
        title={downloadTitle}
        onClick={async () => {
          //default will use all for download
          let downloadBandNames = "all";
          let downloadResolution = resolution;
          const downloadFileType = "tif";

          if (tileImageryMode) {
            downloadResolution = 10;
          } else {
            const numSelectedBands = selectedBands.length;

            if (numSelectedBands >= 1) {
              downloadBandNames = selectedBands.join(", ");
            }
          }

          const estimate = await getImageProcessingEstimate(
            tileImageryMode,
            feature.Geog,
            imageDate,
            downloadResolution,
            downloadFileType,
            downloadBandNames,
            imageID,
            dataScienceModelId,
            setErrorMessage
          );

          if (!estimate) {
            return
          }

          let downloadDialogRequest = async () => {
            setDownloadDialogState(undefined);
            setLoadingText(`Processing ${formattedImageDate} as .tif`);
            setLoading(true);
            setDownloading({
              estimate,
              processingTime: Date.now() + estimate.slowEstimate * 1000,
            });

            if (imageID) {
              await downloadImageFromId(
                imageID,
                setDownloadSize,
                setDownloadProgress,
                setLoadingText,
                formattedImageDate,
                dataScienceModelId
              );
            } else {

              await downloadImageFromBoundingBoxDate(
                feature.Geog,
                imageDate,
                setDownloadSize,
                setDownloadProgress,
                setLoadingText,
                formattedImageDate,
                downloadBandNames,
                downloadResolution,
                dataScienceModelId,
                setErrorMessage
              );
            }

            setLoading(false);
            setDownloadSize(undefined);
            setDownloadProgress(undefined);
            setDownloading(false);
          };

          setDownloadDialogState({
            action: downloadTitle,
            estimate,
            request: downloadDialogRequest,
          });
        }}
      >
        <FileDownloadIcon />
      </span>
    );
  }

  function createSatelliteProductsRangeSpan(
    title,
    imageDate,
  ) {
    return (
      <span
        className={styles.icon}
        title={title}
        onClick={() => {
          setSelectedImageDate(imageDate);
          setSatelliteProductsModalOpen(true);
        }}
      >
        <DateRangeIcon />
      </span>
    );
  }
}

export default connectToContext(PopupComponent);
