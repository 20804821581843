import "./App.css";
import React, { useState } from "react";
import FullPageLoaderComponent from "./components/FullPageLoader/FullPageLoaderComponent";
import AlertPopup from "./components/Alert/AlertComponent";
import MapComponent from "./components/Map/MapComponent";
import LinearProgressBar from "./components/LinearProgressBar/LinearProgressBar";
import InfoBoxComponent from "./components/InfoBox/InfoBoxComponent";
import UserDialog from "./components/UserDialog/UserDialogComponent";
import TileCompositeImageryModeSwitch from "./components/TileCompositeImageryModeSwitch/TileCompositeImageryModeSwitchComponent";
import AuthenticationDialog from "./components/AuthenticationDialog/AuthenticationDialogComponent";

function App() {
  const [downloadProgress, setDownloadProgress] = useState(undefined);
  const [downloadSize, setDownloadSize] = useState(undefined);
  const [downloading, setDownloading] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [infoMessage, setInfoMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [downloadDialogState, setDownloadDialogState] = useState(undefined);
  const [tileImageryMode, setTileImageryMode] = useState(false);
  const [authMode, setAuthMode] = useState(false);

  return (
    <>
      <UserDialog
        downloadDialogState={downloadDialogState}
        setDownloadDialogState={setDownloadDialogState}
      />
      <AuthenticationDialog
        authMode={authMode}
        setAuthMode={setAuthMode}
        setErrorMessage={setErrorMessage}
        setInfoMessage={setInfoMessage}
      />
      <TileCompositeImageryModeSwitch
        tileImageryMode={tileImageryMode}
        setTileImageryMode={setTileImageryMode}
      />
      <InfoBoxComponent />
      {downloading ? (
        <LinearProgressBar
          downloading={downloading}
          downloadProgress={downloadProgress}
          downloadSize={downloadSize}
        />
      ) : null}
      <FullPageLoaderComponent loading={loading} loadingText={loadingText} />
      <MapComponent
        setLoading={setLoading}
        setLoadingText={setLoadingText}
        setAuthMode={setAuthMode}
        setErrorMessage={setErrorMessage}
        setInfoMessage={setInfoMessage}
        setSuccessMessage={setSuccessMessage}
        setDownloadProgress={setDownloadProgress}
        setDownloadSize={setDownloadSize}
        setDownloading={setDownloading}
        setDownloadDialogState={setDownloadDialogState}
        tileImageryMode={tileImageryMode}
      />
      {errorMessage ? (
        <AlertPopup
          autoHideDuration={6000}
          message={errorMessage}
          alertType={"error"}
          setMessage={setErrorMessage}
        />
      ) : null}
      {infoMessage && !errorMessage ? (
        <AlertPopup
          autoHideDuration={6000}
          message={infoMessage}
          alertType={"info"}
          setMessage={setInfoMessage}
        />
      ) : null}
      {successMessage && !errorMessage ? (
        <AlertPopup
          autoHideDuration={6000}
          message={successMessage}
          alertType={"success"}
          setMessage={setSuccessMessage}
        />
      ) : null}
    </>
  );
}

export default App;
