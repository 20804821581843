import React from 'react'
import { baseUrl } from '../../config'
import olLayerTile from 'ol/layer/Tile'
import olSourceXYZ from 'ol/source/XYZ'
import { BasemapOption, BasemapThumbnail, Label } from "@bayer/ol-kit/core/Basemaps/styled"
import { uncertainty } from '../../uncertainty_thumbnail'
import { connectToContext } from "@bayer/ol-kit/core/Provider/utils";
import { tileLoadFunction } from './maputils';


class BasemapRGBThresholdMap extends React.Component {
    handleLayersChange = () => {
        this.forceUpdate()
    }

    componentDidMount() {
        this.props.map.getLayers().on('change', this.handleLayersChange)
    }

    componentWillUnmount() {
        this.props.map.getLayers().un('change', this.handleLayersChange)
    }

    onClick = () => {
        const { map, layerTypeID, onBasemapChanged, date, xyzTileLimitFeature, uncertaintyThreshold, minZoom, maxZoom, tenMeterZoomMin, tenMeterZoomMax } = this.props

        if (!date) {
            return
        }

        const bandName = 'rgb'
        const url = `${baseUrl}/SatelliteImages/preview/${bandName}/${date}/{z}/{x}/{y}?threshold=${uncertaintyThreshold}`
        const source = new olSourceXYZ({
            url: url,
            crossOrigin: 'Anonymous',
            layer: 'THR_RGB',
            //cacheSize: 4096,
            reprojectionErrorThreshold: 0,
            crossOrigin: 'Anonymous',
            tileLoadFunction: (imageTile, src) => tileLoadFunction(imageTile, src, xyzTileLimitFeature, minZoom, maxZoom, tenMeterZoomMin, tenMeterZoomMax)
        })
        const layer = new olLayerTile({
            className: '_ol_kit_basemap_layer',
            preload: 0,
            extent: undefined,
            [layerTypeID]: 'THR_RGB', // make sure we can identify this layer as a layer that has been created from the ol-kit basemap component.
            source
        })
        const layers = map.getLayers()
        const layerArray = layers.getArray()
        const hasBasemap = layerTypeID && layerArray.length ? layerArray[1].get(layerTypeID) : false

        if (hasBasemap) {
            layers.setAt(1, layer)
        } else {
            layers.insertAt(1, layer)
        }
        layers.changed() // ol.Collection insertAt and setAt do not trigger a change event so we fire one manually so that we can rerender to display the active and inactive BasemapOptions
        onBasemapChanged(layer)
    }

    render() {
        const { label, thumbnail, map, layerTypeID } = this.props
        const layerArray = map.getLayers().getArray()
        const isActive = layerArray.length ? layerArray[0].get(layerTypeID) === 'THR_RGB' : false

        return (
            <BasemapOption
                // className='_ol_kit_basemapOption' 
                isActive={isActive}
                onClick={this.onClick}>
                <BasemapThumbnail thumbnail={thumbnail} />
                <Label>{label}</Label>
            </BasemapOption>
        )
    }
}

BasemapRGBThresholdMap.defaultProps = {
    thumbnail: uncertainty,
    label: "ClearSKY Vision RGB Threshold",
    onBasemapChanged: () => { },
    layerTypeID: '_ol_kit_basemap',
    date: undefined,
    xyzTileLimitFeature: undefined,
    uncertaintyThreshold: undefined,
    minZoom: undefined,
    maxZoom: undefined,
    tenMeterZoomMin: undefined,
    tenMeterZoomMax: undefined,
}

export default connectToContext(BasemapRGBThresholdMap)