import * as React from "react";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Countdown from "react-countdown";

export default function LinearProgressBar(props) {
  const [progress, setProgress] = React.useState(0);
  const { downloading, downloadProgress, downloadSize } = props;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((previousProgress) => {
        if (previousProgress === 50) {
          return 50;
        }
        return previousProgress + 5;
      });
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  const normaliseTo50 = (value, min, max) =>
    ((value - min) * 50.0) / (max - min);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return "Any moment";
    } else {
      const hourString =
        hours > 0 ? hours + (hours > 1 ? "hours " : "hour ") : "";
      const minuteString =
        minutes > 0 ? minutes + (minutes > 1 ? "minutes " : "minute ") : "";
      const secondString =
        seconds > 0 ? seconds + (seconds > 1 ? "seconds " : "second ") : "";
      // Render a countdown
      return "~" + hourString + minuteString + secondString + " remaining";
    }
  };

  return (
    <Stack
      sx={{
        left: "30%",
        top: "75%",
        width: "40%",
        position: "absolute",
        zIndex: 99999999,
        backgroundColor: `rgb(255,255,255, 0)`,
      }}
    >
      {downloading.estimate &&
      (!downloadProgress || downloadSize - downloadProgress.transferred > 0) ? (
        <Box
          sx={{
            backgroundColor: "transparent",
            position: "relative",
          }}
        >
          <Typography align="right" variant="h6" color="text.secondary">
            {downloadProgress ? (
              <Countdown
                intervalDelay={5000}
                date={
                  Date.now() +
                  (downloadProgress.remaining
                    ? downloadProgress.remaining / downloadProgress.speed
                    : (downloadSize - downloadProgress.transferred) /
                      downloadProgress.speed) *
                    1000
                }
                renderer={renderer}
              />
            ) : (
              <Countdown
                date={downloading.processingTime}
                renderer={renderer}
              />
            )}
          </Typography>
        </Box>
      ) : null}

      <LinearProgress
        variant="determinate"
        value={
          downloadProgress && downloadSize
            ? 50.0 +
              normaliseTo50(downloadProgress.transferred, 0.0, downloadSize)
            : progress
        }
        sx={{
          backgroundColor: "black",
          "& .MuiLinearProgress-bar": {
            backgroundColor: `green`,
          },
        }}
      />
    </Stack>
  );
}
