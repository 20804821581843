import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DragDropBand.css";

const DragDropBand = (props) => {
  const { defaultBands, selectedBands, setSelectedBands } = props;

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newSelectedBands = Array.from(selectedBands);
    const [removed] = newSelectedBands.splice(result.source.index, 1);
    newSelectedBands.splice(result.destination.index, 0, removed);
    setSelectedBands(newSelectedBands);
  };

  const onAdd = (band) => {
    if (!selectedBands.includes(band)) {
      setSelectedBands([...selectedBands, band]);
    } else {
      setSelectedBands(
        selectedBands.filter((selectedBand) => selectedBand !== band)
      );
    }
  };

  const onRemove = (index) => {
    setSelectedBands(selectedBands.filter((_, i) => i !== index));
  };

  return (
    <div onContextMenu={(e) => e.preventDefault()}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {defaultBands.map((band) => (
          <button
            key={band}
            onClick={() => onAdd(band)}
            style={{
              backgroundColor: selectedBands.includes(band) ? "green" : "red",
            }}
          >
            {band}
          </button>
        ))}
      </div>
      <br />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ol
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="droppable-list"
            >
              {selectedBands.map((band, index) => (
                <Draggable key={band} draggableId={band} index={index}>
                  {(provided, snapshot) => {
                    const title = !defaultBands.includes(band)
                      ? "Right click to remove"
                      : null;
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          onRemove(index);
                        }}
                        className="draggable-item"
                        title={title}
                      >
                        {band}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </ol>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DragDropBand;
