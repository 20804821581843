import * as React from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function IndexDropDown(props) {
  const [bandOne, setBandOne] = React.useState("");
  const [bandTwo, setBandTwo] = React.useState("");
  const { addIndexToBands, defaultBands, setInfoMessage } = props;
  const handleChangeBandOne = (event) => {
    setBandOne(event.target.value);
  };
  const handleChangeBandTwo = (event) => {
    setBandTwo(event.target.value);
  };
  const addIndex = () => {
    const newIndex = `[${bandOne}_${bandTwo}]`;

    addIndexToBands(newIndex);

    setBandOne("");
    setBandTwo("");

    setInfoMessage(
      `Added index (${bandOne} - ${bandTwo}) / (${bandOne} + ${bandTwo}) `
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 80 }} variant="filled">
        <InputLabel htmlFor="band-select-one">B1</InputLabel>
        <NativeSelect
          id="band-select-one"
          value={bandOne}
          onChange={handleChangeBandOne}
          input={<BootstrapInput />}
        >
          <option aria-label="None" value="" />
          {defaultBands.map((band, index) => (
            <option key={index} disabled={bandTwo === band} value={band}>
              {band}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormControl sx={{ m: 1, width: 80 }} variant="filled">
        <InputLabel htmlFor="band-select-two">B2</InputLabel>
        <NativeSelect
          id="band-select-two"
          value={bandTwo}
          onChange={handleChangeBandTwo}
          input={<BootstrapInput />}
        >
          <option aria-label="None" value="" />
          {defaultBands.map((band, index) => (
            <option key={index} disabled={bandOne === band} value={band}>
              {band}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      {bandOne && bandTwo ? (
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          color="success"
          onClick={addIndex}
        >
          Add index calculated from
          <br />({bandOne} - {bandTwo}) / ({bandOne} + {bandTwo})
          <br />[{bandOne}_{bandTwo}] to band order
        </Button>
      ) : null}
    </div>
  );
}
