import React, { useRef, useEffect, useState } from "react";
import "./Table.css"; // Make sure to import the CSS file you created
import UpdateIcon from '@mui/icons-material/Update';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { retrieveSatelliteProductsMetadataForAreaPeriod, retrieveSatelliteAcquisitionPlansForArea } from '../../services/ClearskyAPI';

function mapSatelliteName(satellite) {
    switch (satellite) {
        case 'S1A':
        case 'S1B':
        case 'Sentinel1':
            return 'Sentinel 1';
        case 'S2A':
        case 'S2B':
        case 'Sentinel2':
            return 'Sentinel 2';
        case 'L8':
        case 'L9':
        case 'Landsat89':
            return 'Landsat 8/9';
        default:
            return satellite; // Return the original value if it doesn't match known satellites
    }
}
const getTodayYesterdayDateYYYYMMDDStr = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return { yesterday: yesterday.toISOString().split('T')[0], today: today.toISOString().split('T')[0] }; // Converts date to YYYY-MM-DD format
};

const renderDateIcon = (dateConstellations, satelliteConstellation, targetDate) => {
    const icon = dateConstellations.satelliteConstellations.includes(satelliteConstellation) ? (
        dateConstellations.date <= targetDate ? (
            <CheckIcon titleAccess="Product included in image for selected date" />
        ) : (
            <UpdateIcon titleAccess="Product expected to be included in later images" />
        )
    ) : null;

    if (icon && dateConstellations.acquisitionPlan && dateConstellations.lost) {
        return <CancelIcon titleAccess="Product was expected to be included but could not be found - LOST PRODUCT" />;
    }

    return icon;

}

const renderSatelliteConstellations = (uniqueSatelliteConstellations, datesWithSatelliteConstellations, targetDate) => {
    return uniqueSatelliteConstellations.map((satelliteConstellation, numberIndex) => (
        <tr key={numberIndex}>
            <td className="sticky-col">{satelliteConstellation}</td>
            {datesWithSatelliteConstellations.map((dateConstellations, dateIndex) => (
                <td key={dateIndex}>
                    {renderDateIcon(dateConstellations, satelliteConstellation, targetDate)}
                </td>
            ))}
        </tr>
    ));
};

const SatelliteProductsComponent = ({ popUpSelectedAreaDate: { wkt, zone, xpos, ypos, selectedImageDate }, setErrorMessage }) => {
    const [datesWithSatelliteConstellations, setDatesWithSatelliteConstellations] = useState([]);

    const uniqueSatelliteConstellations = [
        ...new Set(datesWithSatelliteConstellations.flatMap((item) => item.satelliteConstellations)),
    ].sort((a, b) => a - b); // Extract and sort unique satelliteConstellations

    const targetDate = selectedImageDate.split("T")[0];
    const tableRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {

        if (datesWithSatelliteConstellations.length === 0) {
            return;
        }

        const convertWheelToHorizontal = (e) => {
            if (e.deltaY === 0) return; // Do nothing if there's no vertical scrolling

            e.preventDefault();
            if (containerRef.current) {
                containerRef.current.scrollLeft += e.deltaY + e.deltaX; // Add horizontal scrolling
            }
        };

        const tableContainer = containerRef.current;
        if (tableContainer) {
            tableContainer.addEventListener("wheel", convertWheelToHorizontal, { passive: false });
        }

        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener("wheel", convertWheelToHorizontal);
            }
        };
    }, [datesWithSatelliteConstellations]);

    useEffect(() => {
        const fetchSatelliteProductsMetaData = async () => {

            let data = []
            const groupedData = new Map();
            const daysBeforeToRetrieve = 30;
            const daysAfterToRetrieve = 4;

            const { yesterday, today } = getTodayYesterdayDateYYYYMMDDStr();
            const yesterdayDate = new Date(yesterday);
            const todayDate = new Date(today);
            const dateSelected = new Date(selectedImageDate);
            const fromDate = new Date(dateSelected);
            let toDate = new Date(dateSelected);

            fromDate.setDate(fromDate.getDate() - daysBeforeToRetrieve);
            toDate.setDate(toDate.getDate() + daysAfterToRetrieve);

            if (toDate > todayDate) {
                toDate = todayDate
            }

            if (wkt) {
                const compositeData = await retrieveSatelliteProductsMetadataForAreaPeriod(
                    {
                        setErrorMessage,
                        wkt: wkt,
                        fromDate: fromDate,
                        toDate: toDate
                    });

                compositeData.SatelliteProductsForComposite.forEach(product => {
                    const { ImageDate, SatelliteConstellation } = product;
                    const date = ImageDate.split('T')[0]; // Assuming the date is in the format '2023-07-06T00:00:00Z'

                    if (!groupedData.has(date)) {
                        groupedData.set(date, new Set());
                    }

                    groupedData.get(date).add(mapSatelliteName(SatelliteConstellation));
                });

            } else {
                const tilesData = await retrieveSatelliteProductsMetadataForAreaPeriod(
                    {
                        setErrorMessage,
                        satelliteZone: { "zone": zone, "xpos": xpos, "ypos": ypos },
                        fromDate: fromDate,
                        toDate: toDate
                    });

                tilesData.TileSatelliteProducts[0].SatelliteProductsForTile.forEach(product => {
                    const { ImageDate, SatelliteConstellation } = product;
                    const date = ImageDate.split('T')[0]; // Assuming the date is in the format '2023-07-06T00:00:00Z'

                    if (!groupedData.has(date)) {
                        groupedData.set(date, new Set());
                    }

                    groupedData.get(date).add(mapSatelliteName(SatelliteConstellation));
                });
            }

            // Iterate over the sorted array to push items into `data`
            Array.from(groupedData).forEach(([date, constellations]) => {
                data.push({
                    date,
                    satelliteConstellations: Array.from(constellations)
                });
            });

            if (yesterdayDate >= fromDate && yesterdayDate <= toDate) {
                const satelliteAcquisitionPlans = await retrieveSatelliteAcquisitionPlansForArea({ wkt, setErrorMessage })

                satelliteAcquisitionPlans.Plans.forEach(plan => {
                    if (plan.AcquisitionPlans && plan.AcquisitionPlans.length > 0) {
                        plan.AcquisitionPlans.forEach(acquisitionPlan => {
                            const date = plan.Date.split("T")[0]
                            const satellite = mapSatelliteName(acquisitionPlan.Satellite);
                            const existingDate = data.find(d => d.date === date);

                            if (existingDate) {
                                // If the date exists, add the satellite to the satelliteConstellations array if it's not already included
                                if (!existingDate.satelliteConstellations.includes(satellite)) {
                                    existingDate.satelliteConstellations.push(satellite);
                                }
                            } else {
                                const lost = satellite === mapSatelliteName("L8")
                                    ? date === yesterdayDate
                                    : acquisitionPlan.PublicationStatus.includes("LOST");

                                // If the date doesn't exist, create a new entry with the current satellite
                                data.push({
                                    date: date,
                                    satelliteConstellations: [satellite],
                                    acquisitionPlan: true,
                                    lost: lost,
                                });
                            }
                        });
                    }
                });
            }

            data = data.sort((a, b) => a.date.localeCompare(b.date));

            setDatesWithSatelliteConstellations(data);
        };

        fetchSatelliteProductsMetaData();

    }, []);

    useEffect(() => {
        requestAnimationFrame(() => {
            const targetIndex = datesWithSatelliteConstellations.findIndex(
                (item) => item.date === targetDate
            );
            if (targetIndex !== -1 && tableRef.current) {
                const targetElement =
                    tableRef.current.getElementsByTagName("th")[targetIndex];
                if (targetElement) {
                    // Scroll the target element into view
                    targetElement.scrollIntoView({
                        behavior: "smooth", // Optional: Defines the transition animation.
                        block: "nearest", // Optional: Defines vertical alignment.
                        inline: "center", // Aligns the element to the center of the container horizontally.
                    });
                }
            }
        });
    }, [datesWithSatelliteConstellations]);

    return (
        <>
            {datesWithSatelliteConstellations.length === 0 ?
                <div>
                    <h1 style={{ textAlign: "center", color: "black" }}>Loading...</h1>
                    <br />
                    <center>
                        <UpdateIcon
                            style={{ width: "25%", height: "25%" }}
                            className="rotateAnimation"
                            titleAccess="Loading satellite products for selected image date"
                        />
                    </center>
                </div> :
                <div className="table-container" ref={containerRef}>
                    <table className="table" ref={tableRef}>
                        <thead>
                            <tr>
                                <th className="sticky-header sticky-col">
                                    Satellite <br />
                                    Constellation
                                </th>
                                {/* Apply both sticky classes */}
                                {datesWithSatelliteConstellations.map((item, index) => (
                                    <th key={index} className={item.date === targetDate ? "highlighted-date" : ""}>{item.date}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {renderSatelliteConstellations(uniqueSatelliteConstellations, datesWithSatelliteConstellations, targetDate)}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
};

export default SatelliteProductsComponent;
