import React, { Component } from "react";
import styles from "./FullPageLoader.module.css";

class FullPageLoaderComponent extends Component {
  render() {
    const { loading, loadingText } = this.props;

    if (!loading) return null;

    return (
      <>
        <div className={styles.loadercontainer}>
          <div className={styles.loader}>
            <div className={styles.spinner}>
              <span>{loadingText}</span>
              <div className={styles.halfspinner}></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FullPageLoaderComponent;
